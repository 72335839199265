import axios from 'axios'
import { t } from 'i18next';
import { toast } from 'react-toastify';

const userLang= localStorage.getItem("userLang") || navigator.language;
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': userLang,
  }
});

instance.interceptors.response.use(
  response => response, // Simply return the response if no error
  error => {
    // Check if the error is due to file size limit
    if (error.response && error.response.status === 413) {
      // Handle the file size error
      // You can customize the error message or perform any action needed
      toast.error(t('Имате право да качвате видео до 200mb'));
      document.getElementById("loading-indicator").classList.remove('open');
      return Promise.reject(error); // Reject the promise to trigger.catch()
    }
    // For other errors, simply return the error to trigger.catch()
    return Promise.reject(error);
  }
);

export default instance