import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./AddToHomeScreenBanner.css";
import { Dialog, DialogContent } from "@material-ui/core";
import Images from "../../../assets/uploaded_images.json"
const CloseModal =Images["close-modal.svg"];
function AddToHomeScreenBanner({ setShowFirebaseBanner }) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="addToHomeScreenBanner">
      <div className="main-banner-container">
        <h className="banner-content">Experience notifications</h>

        <div className="showTips" onClick={() => setOpenModal(true)}>show tips</div>
        <div className="close-button" onClick={() => setShowFirebaseBanner(false)}>
          <CloseIcon />
        </div>
        <Dialog
          disableScrollLock={true}
          open={openModal}
          fullWidth="md"
          maxWidth="md"
          PaperProps={{
            style: {
              background: "#FFFFFF",
              boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              padding:15,
            },
          }}
          onClose={() => setOpenModal(false)}
        >
          <DialogContent  className="tips-content">
          <div className="cancel-button">

            <img loading="lazy"
              src={CloseModal}
              className="close-icon"
              alt=""
              onClick={() => setOpenModal(false)}
            />
          </div>

            <p className="sub-title">Tips to add experience notifications</p>
            <ol>
              <li>Open Share menu</li>
              <li>Tap on "Add to Home Screen" button</li>
            </ol>
          </DialogContent>
        </Dialog>

        
      </div>
    </div>
  );
}

export default AddToHomeScreenBanner;
