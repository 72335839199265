import React, { useState, useMemo, useEffect } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import "./Header.css";

import { toast } from "react-toastify";

import { IconContext } from "react-icons/lib";

import Images from "../../../assets/uploaded_images.json";

import PlacesAutocomplete from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { getUserLang } from '../../../hooks/useTranslator';
import { filtersProperty, geocodeAddressLocation } from "../../../features/property/propertySlice";

/* Components */
import SideBarMenu from "./HeaderSideBar";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import { supportedLanguages } from "../../common/search/SupportedLanguages";

/* Images */
const AddIconProperty = Images["add-property-icon.svg"];
const Logo = Images["Logo.svg"];
const HeaderSearchIcon = Images["home-page-search-icon.svg"];

const Header = () => {
    const { t } = useTranslation();
    const colorValue = useMemo(() => ({ color: "#fff" }), []);
    const userLanguage = getUserLang();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const [address, setAddress] = useState("");
    const [isLangSupported, setIsLangSupported] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    
    useEffect(() => {
        const supportedLanguage = supportedLanguages.find((lang) => lang.code === userLanguage);

        if (supportedLanguage) {
            setIsLangSupported(true);
        }
    }, [userLanguage])

    /* Handle select cities */
    const handleSelect = async (value) => {
        let resultAddress = await geocodeAddressLocation(value);
        
        if (
            (!resultAddress?.formatted_address &&
                !resultAddress?.latitude &&
                !resultAddress?.longitude &&
                !resultAddress?.municipality &&
                !resultAddress?.region) ||
            !resultAddress?.continent
        ) {
            toast.error("Please choose another suitable and accurate address");
        } else {
            let newSearchQuery = {
                address: resultAddress?.formatted_address,
                originalSelectedAddress: value
            };
            if (resultAddress?.municipality) {
                newSearchQuery.municipality = resultAddress?.municipality;
            }
            if (resultAddress?.region) {
                newSearchQuery.region = resultAddress?.region;
            }
            if (resultAddress?.continent) {
                newSearchQuery.continent = resultAddress?.continent;
            }
            if (resultAddress?.country) {
                newSearchQuery.country = resultAddress?.country;
            }


            dispatch(filtersProperty(newSearchQuery));


            setAddress("");

            navigate("/search-properties");
        }
    };

    /* Handle click search icon and redirect to search-properties page */
    const handleClick = () => {
        navigate("/search-properties");

        dispatch(filtersProperty({
            otherPromises: {},
            propertyTypes: [],
            propertyNeighborhood: [],
            scrollPosition: 0,
            selectCloseTo: [],
            selectConstructionStage: [],
            selectConstructionType: [],
            selectEquipment: [],
            selectFloorType: [],
            selectHeating: [],
            selectOtherPremises: [],
            valueBudget: [0, 500000],
            address: "",
            beforeSelectNeighbothood: "",
            continent: "",
            country: "",
            municipality: "",
            neighborhood: "",
            region: "",
            locationLatitude: "",
            locationLongitude: "",
            sortBy: {},
            maxSquareMeter: "",
            minSquareMeter: "",
            maxYearBuilt: "",
            minYearBuilt: "",
            originalSelectedAddress: ""
        }));

        dispatch(filtersProperty({
            scrollPosition: 0
        }))
    };

    /* Handle focus input search filed */
    const handleFocus = () => {
        setIsFocused(true)
    };

    /* Handle unfocus input search filed */
    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <header className="header-without-login">
            <IconContext.Provider value={colorValue}>
                <nav className="navbar">
                    <div className="nav-items-mobile">
                        <Link to="/" className="navbar-logo">
                            <img loading="lazy" src={Logo} alt="" />
                        </Link>

                        <div className="display-translation-switcher">
                            <LangSwitcher />
                        </div>

                        <div className="nav-items-mobile-menu">
                            <SideBarMenu />
                        </div>
                    </div>

                    <div className="clearfix nav-items" style={{ width: "100%" }}>
                        <Link to="/" className="navbar-logo">
                            <img loading="lazy" src={Logo} alt="" />
                        </Link>

                        <div className="display-translation-switcher">
                            <LangSwitcher />
                        </div>

                        <ul className="nav-menu">
                            <li className="nav-item">
                                <LangSwitcher />
                            </li>

                            <li className="nav-item">
                                <Link to="/" className={location?.pathname === "/" ? "nav-links active" : "nav-links"}> {t("Начало")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/third-party?lawyers" className={location?.pathname === "/third-party" ? "nav-links active" : "nav-links"}> {t("Професионалисти")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/blog" className={location?.pathname === "/blog" ? "nav-links active" : "nav-links"}> {t("Блог")} </Link>
                            </li>
                        </ul>

                        {!isLangSupported && <form className={isFocused ? "search focus-input-filed" : "search"}>
                            <div className="search-places-auto-complete-container">
                                <PlacesAutocomplete value={validateInput(address) || ""} onChange={setAddress} onSelect={handleSelect}>
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <img src={HeaderSearchIcon} className="search-icon" onClick={handleClick} alt="" />

                                            <input className="search-field" {...getInputProps({ placeholder: `${t("Град")}` })}
                                                style={{
                                                    fontSize: "18px",
                                                    fontFamily: userLanguage === "bg" || userLanguage === "ru" ? "Manrope" : 'Poppins',
                                                    color: "background: rgba(234, 234, 234, 1)",
                                                    border: "none !important",
                                                    outline: "none",
                                                }}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                            />

                                            <div className="autocomplete-dropdown-container-home">
                                                {loading ? <div>...loading</div> : null}

                                                {suggestions.map((suggestion) => {
                                                    const style = {
                                                        backgroundColor: suggestion.active
                                                            ? "#00BCD4"
                                                            : "#ffffff",
                                                        fontSize: "18px",
                                                        fontFamily: userLanguage === "bg" || userLanguage === "ru" ? "Manrope" : 'Poppins',
                                                        color: suggestion.active ? "#ffffff" : "#00BCD4",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                    };

                                                    return (
                                                        <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                        </form>}

                        <div className="right-part">
                            <Link to="/login" className="add-property clearfix" style={{ cursor: "pointer" }}>
                                <img loading="lazy" src={AddIconProperty} alt="" />

                                <p className="add-text">{t("Добави имот")}</p>
                            </Link>

                            <Link to="/login" className="login-button"> {t("Вход")} </Link>
                            <Link to="/registration-about" className="registration-button"> {t("Регистрация")} </Link>

                            <SideBarMenu />
                        </div>
                    </div>
                </nav>
            </IconContext.Provider>
        </header>
    );
};

export default Header;