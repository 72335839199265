import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../config/axiosConfig';
import Cookie from 'js-cookie';
import { toast } from "react-toastify";
import { setLocalStorageItem } from '../../config/localStorageEncryption';

let initialState = {
    emailsSubscriptionDetails: {
        activePersona: null,
        isOpenModal: false,
        shouldOpenAfterLogin:false
    },
}

const getToken = () => Cookie.get('userToken');
const token = getToken();
const config = {
    headers: { Authorization: `Bearer ${token}` }
}
export const changeEmailsSubscriptionDetails = createAsyncThunk(
    'user/changeUserEmailsSubscription',
    async (payload, thunkAPI) => {
        try {
            const response = await instance.post('user/changeUserEmailsSubscription', payload, config)

            if(response?.data!==undefined){
                const { userId, email: userEmail, activePersona, familyName, createdAt, isActive, name, phoneNumber, profilePicture,unsubscribedEmailNotifications } = response?.data.findUser
                const findUser = { userId, activePersona, userEmail, familyName, createdAt, isActive, name, phoneNumber, profilePicture,unsubscribedEmailNotifications }
                setLocalStorageItem('authenticatedUser',findUser)

                return response
            } else{
                return
            }
           
          
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });

const emailsSubscriptionSlice = createSlice({
    name: "emailssubscription",
    initialState,
    reducers: {
        emailsSubscriptionDetails: (state, action) => {
            return {
                ...state,
                emailsSubscriptionDetails: {
                    ...state.emailsSubscriptionDetails,
                    ...action?.payload
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeEmailsSubscriptionDetails.pending, (state) => {
                state.loading = 'loading'
            })
            .addCase(changeEmailsSubscriptionDetails.fulfilled, (state, { payload }) => {
                state.loading = 'loaded'
                state.emailsSubscriptionDetails = payload.data
                toast.success("Твоят имейл абонамент бе актуализиран успешно!");
            })
            .addCase(changeEmailsSubscriptionDetails.rejected, (state, action) => {
                const { error } = action.meta.arg
                state.loading = 'rejected'
                state.error = error
                state.emailsSubscriptionDetails = []
                console.error(action.error.message)
                toast.error("Error Updating User Emails Subscription");
            });

    }
})

export const { emailsSubscriptionDetails } = emailsSubscriptionSlice.actions;
export default emailsSubscriptionSlice.reducer