import React, { useEffect, useRef, useState, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { messageNotifications, notifications, readNotifications } from "../../../features/user/authSlice";
import "./HeaderVirtualAssistant.css";

import { IconContext } from "react-icons/lib";

import Images from "../../../assets/uploaded_images.json";

import PlacesAutocomplete from "react-places-autocomplete";

import { filtersProperty, geocodeAddressLocation } from "../../../features/property/propertySlice";
import { getVirtualAssistantCertifications, virtualAssistantRedirectNotification } from "../../../features/user/virtualAssistantSlice";
import { listServiceRequests, resetServiceRequest } from "../../../features/common/serviceRequestSlice";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { validateInput } from "./../../../helpers/userInputEscaper";
import { toast } from "react-toastify";
import { getLocalStorageItem } from "../../../config/localStorageEncryption";
import { getUserLang } from '../../../hooks/useTranslator';

/* Components */
import MessageNotification from "../../common/Notifications/MessageNotification";
import Notifications from "./../../common/Notifications/Notification";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import SideBar from "./sideBar";
import ProfileMenu from "../../common/ProfileMenu/ProfileMenu";

/* Images */
const AccountGroup = Images["account-group.svg"];
const TextBoxCheckOutline = Images["text-box-check-outline.svg"];
const Logo = Images["Logo.svg"];
const HeaderSearchIcon = Images["home-page-search-icon.svg"];

const HeaderVirtualAssistant = () => {
    const showMenu = useMediaQuery({
        query: "(max-width: 1127px)",
    });

    const hideSearch = useMediaQuery({
        query: "(max-width: 767px)",
    });

    const colorValue = useMemo(() => ({ color: "#fff" }), []);

    const userLanguage = getUserLang();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const persona = getLocalStorageItem("activePersonaDetails");
    const virtualAssistantId = persona?.virtualAssistantId;

    const [address, setAddress] = useState("");

    const [openNotificaration, setOpenNotification] = useState(false);
    const [openNotificaration2, setOpenNotification2] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const messageRef = useRef(null);
    const messageRef2 = useRef(null);

    useEffect(() => {
        dispatch(getVirtualAssistantCertifications(virtualAssistantId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [virtualAssistantId]);

    /* Handle click search icon */
    const handleClick = () => {
        navigate("/search-properties");

        dispatch(filtersProperty({
            otherPromises: {},
            propertyTypes: [],
            propertyNeighborhood: [],
            scrollPosition: 0,
            selectCloseTo: [],
            selectConstructionStage: [],
            selectConstructionType: [],
            selectEquipment: [],
            selectFloorType: [],
            selectHeating: [],
            selectOtherPremises: [],
            valueBudget: [0, 500000],
            address: "",
            beforeSelectNeighbothood: "",
            continent: "",
            country: "",
            municipality: "",
            neighborhood: "",
            region: "",
            locationLatitude: "",
            locationLongitude: "",
            sortBy: {},
            maxSquareMeter: "",
            minSquareMeter: "",
            maxYearBuilt: "",
            minYearBuilt: "",
            originalSelectedAddress: ""
        }));

        dispatch(filtersProperty({
            scrollPosition: 0
        }))
    };

    /* Handle select cities */
    const handleSelect = async (value) => {
        let resultAddress = await geocodeAddressLocation(value);

        if (
            !resultAddress?.formatted_address &&
            !resultAddress?.latitude &&
            !resultAddress?.longitude &&
            !resultAddress?.municipality &&
            !resultAddress?.region &&
            !resultAddress?.continent
        ) {
            toast.error("Please choose another suitable and accurate address");
        } else {
            let newSearchQuery = {
                address: resultAddress?.formatted_address,
                originalSelectedAddress: value
            };
            if (resultAddress?.municipality) {
                newSearchQuery.municipality = resultAddress?.municipality;
            }
            if (resultAddress?.region) {
                newSearchQuery.region = resultAddress?.region;
            }
            if (resultAddress?.continent) {
                newSearchQuery.continent = resultAddress?.continent;
            }
            if (resultAddress?.country) {
                newSearchQuery.country = resultAddress?.country;
            }

            dispatch(filtersProperty(newSearchQuery));
            setAddress("");
            navigate("/search-properties");
        }
    };

    /* Handle click notification */
    const handleClickNotification = (item) => {
        let redirectUrl = "";
        if (item?.notificationType === "cancelledJobRequest") {
            dispatch(listServiceRequests({
                providerId: virtualAssistantId,
                serviceType: "assistance",
            }));
            dispatch(resetServiceRequest());
            redirectUrl = "/virtual/dashboard?" + item?.senderPersona + "s";
        } else if (item?.notificationType === "receivePublicReview") {
            redirectUrl =
                "third-party/virtual-assistant-profile/" + virtualAssistantId;
        } else if (item?.notificationType === "receivePayment") {
            dispatch(listServiceRequests({
                providerId: virtualAssistantId,
                serviceType: "assistance",
            }));
            dispatch(resetServiceRequest());
            redirectUrl = "/virtual/dashboard?persona=" + item?.senderPersona + "s";
        }

        if (item?.notificationType === "signViaReferralLink") {
            dispatch(listServiceRequests({
                providerId: virtualAssistantId,
                serviceType: "assistance",
            }));
            dispatch(resetServiceRequest());
            redirectUrl = "/virtual/dashboard?section=inquiries";
        }

        if (item?.notificationType === "receivePayment") {
            redirectUrl = `/property-details?${item.propertyId}`;
        } else if (item?.notificationType === "atomChatNewMessage") {
            if (item?.senderPersona === "buyer" || item?.senderPersona === "seller") {
                redirectUrl = `/virtual/dashboard${item?.senderPersona ? `?customerType=${item?.senderPersona}` : ""}${item?.serviceRequestID ? `&activeServiceRequest=${item?.serviceRequestID}` : ""}`;
            }
        }

        dispatch(virtualAssistantRedirectNotification(redirectUrl));

        dispatch(readNotifications(item?.notificationId)).then((r) => {
            if (r?.payload?.data?.status) {
                setOpenNotification(false);
                dispatch(notifications());
                dispatch(messageNotifications({ filters: { notificationType: "atomChatNewMessage" } }));
            }
        });
    };

    /* Handle focus input search filed */
    const handleFocus = () => {
        setIsFocused(true)
    };

    /* Handle unfocus input search filed */
    const handleBlur = () => {
        setIsFocused(false);
    };

    /* Scroll to top even if I'm on the same page */
    const handleClickScrollToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <header className="header-virtual-assistant">
            <IconContext.Provider value={colorValue}>
                <nav className="navbar">
                    <div className="clearfix" style={{ width: "100%" }}>
                        <Link to="/virtual" className="navbar-logo">
                            <img loading="lazy" src={Logo} alt="" />
                        </Link>

                        <ul className="nav-menu">
                            <li className="languages">
                                <LangSwitcher />
                            </li>
                            <li className="nav-item">
                                <Link to="/virtual" className={location?.pathname === "/virtual" ? "nav-links active" : "nav-links"}> {t("Начало")} </Link>
                            </li>

                            <li className="nav-item" onClick={handleClickScrollToTop}>
                                <Link to="/virtual/dashboard?customers=buyers" className={location?.pathname === "/virtual/dashboard" && location?.search === "?customers=buyers" ? "nav-links active" : "nav-links"}> {t("Купувачи")} </Link>
                            </li>

                            <li className="nav-item" onClick={handleClickScrollToTop}>
                                <Link to="/virtual/dashboard?customers=sellers" className={location?.pathname === "/virtual/dashboard" && location?.search === "?customers=sellers" ? "nav-links active" : "nav-links"}> {t("Продавачи")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/third-party?lawyers" className={location?.pathname === "/third-party" ? "nav-links active" : "nav-links"}> {t("Професионалисти")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/blog" className={location?.pathname === "/blog" ? "nav-links active" : "nav-links"}> {t("Блог")} </Link>
                            </li>
                        </ul>

                        {!hideSearch && (
                            <form className={isFocused ? "search focus-input-filed" : "search"}>
                                <div className="search-places-auto-complete-container">
                                    <PlacesAutocomplete value={validateInput(address) || ""} onChange={setAddress} onSelect={handleSelect}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <img src={HeaderSearchIcon} className="search-icon" onClick={handleClick} alt="" />

                                                <input className="search-field" {...getInputProps({ placeholder: `${t("Град")}` })}
                                                    style={{
                                                        fontSize: "18px",
                                                        fontFamily: userLanguage === "bg" || userLanguage === "ru" ? "Manrope" : 'Poppins',
                                                        color: "rgba(186, 186, 186, 1);",
                                                        border: "none !important",
                                                        outline: "none",
                                                    }}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                />

                                                <div className="autocomplete-dropdown-container-home">
                                                    {loading ? <div>...loading</div> : null}

                                                    {suggestions.map((suggestion) => {
                                                        const style = {
                                                            backgroundColor: suggestion.active
                                                                ? "#00BCD4"
                                                                : "#ffffff",
                                                            fontSize: "18px",
                                                            fontFamily: userLanguage === "bg" || userLanguage === "ru" ? "Manrope" : 'Poppins',
                                                            color: suggestion.active ? "#ffffff" : "#00BCD4",
                                                            padding: "5px",
                                                            cursor: "pointer",
                                                        };

                                                        return (
                                                            <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                                {suggestion.description}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                            </form>
                        )}

                        <div className="right-part">
                            <div className="languages">
                                <LangSwitcher />
                            </div>

                            {getLocalStorageItem("activePersonaDetails") ? (
                                getLocalStorageItem("activePersonaDetails")?.isCertified ? (
                                    <div className="account-group" onClick={handleClickScrollToTop}>
                                        <Link to="/virtual/dashboard?buyers" className="clearfix link">
                                            <img loading="lazy" src={AccountGroup} alt="" />

                                            <p className="title">{t("Моите клиенти")}</p>
                                        </Link>
                                    </div>
                                ) : (
                                    <div className="account-group">
                                        <Link to="/virtual/education" className="clearfix link">
                                            <img loading="lazy" src={TextBoxCheckOutline} alt="" />

                                            <p className="title">{t("Изкарай теста")}</p>
                                        </Link>
                                    </div>
                                )
                            ) : null}

                            <div className="notification-icon">
                                <Notifications handleNotification={handleClickNotification} setOpenNotification={setOpenNotification} openNotificaration={openNotificaration} messageRef={messageRef} />
                            </div>

                            <div className="notification-icon">
                                <MessageNotification handleNotification={handleClickNotification} setOpenNotification={setOpenNotification2} openNotificaration={openNotificaration2} messageRef={messageRef2} />
                            </div>
                            
                            <div className="bell">
                                <ProfileMenu personaId={virtualAssistantId} />
                            </div>

                            {showMenu && (
                                <div className="sidebar-menu-icon">
                                    <SideBar virtualAssistantId={virtualAssistantId} />
                                </div>
                            )}
                        </div>
                    </div>
                </nav>
            </IconContext.Provider>
        </header>
    );
};

export default HeaderVirtualAssistant;